var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accentColor":"primary"}},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|max:100","name":"Nama Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":"Nama Role","placeholder":"Nama Role","invalid-feedback":errors[0],"isValid":!errors[0]},model:{value:(_vm.form.role_name),callback:function ($$v) {_vm.$set(_vm.form, "role_name", $$v)},expression:"form.role_name"}})]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Deskripsi Role","placeholder":"Deskripsi Role","rows":"9","isValid":true},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('table',{staticClass:"table center-aligned-table table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('strong',[_vm._v("Menu")])]),_c('th',{attrs:{"scope":"row"}},[_c('strong',[_vm._v("SubMenu")])]),_c('th',{staticStyle:{"width":"50%"},attrs:{"scope":"row"}},[_c('strong',[_vm._v("Aksi")])])])]),_c('tbody',[_vm._l((_vm.form.assignments),function(field,index){return [(
                        field[Object.keys(field)[0]] == 0 ||
                        field[Object.keys(field)[0]] == 1
                      )?_c('tr',{key:index},[_c('td',{attrs:{"scope":"row"}},[_c('strong',[_vm._v(_vm._s(index))])]),_c('td',{attrs:{"scope":"row"}},[_vm._v("-")]),_c('td',{attrs:{"scope":"row"}},_vm._l((field),function(val,index_field){return _c('b-form-checkbox',{key:index + '_' + index_field,attrs:{"inline":"","value":"1","unchecked-value":"0"},model:{value:(_vm.form.assignments[index][index_field]),callback:function ($$v) {_vm.$set(_vm.form.assignments[index], index_field, $$v)},expression:"form.assignments[index][index_field]"}},[_vm._v(_vm._s(index_field))])}),1)]):_vm._l((field),function(child,index_child){return _c('tr',{key:index_child},[_c('td',{attrs:{"scope":"row"}},[(Object.keys(field)[0] == index_child)?_c('strong',[_vm._v(_vm._s(index))]):_vm._e()]),_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(index_child))]),_c('td',{attrs:{"scope":"row"}},_vm._l((child),function(val,index_child_field){return _c('b-form-checkbox',{key:index +
                              '_' +
                              index_child +
                              '_' +
                              index_child_field,attrs:{"inline":"","value":"1","unchecked-value":"0"},model:{value:(
                              _vm.form.assignments[index][index_child][
                                index_child_field
                              ]
                            ),callback:function ($$v) {_vm.$set(_vm.form.assignments[index][index_child], 
                                index_child_field
                              , $$v)},expression:"\n                              form.assignments[index][index_child][\n                                index_child_field\n                              ]\n                            "}},[_vm._v(_vm._s(index_child_field))])}),1)])})]})],2)])])],1),_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"6"}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"success","size":"sm"}}):_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Simpan")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }